import axios from "../axios.config";

const state = {
  devices: []
};

const actions = {
  async loadDevices({ commit }) {
    try {
      const { data } = await axios.get("/device/");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_DEVICE_LIST", data);
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
};

const mutations = {
  SET_DEVICE_LIST: (state, data) => {
    state.devices = data.data;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
