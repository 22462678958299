import axios from "../axios.config";
import {serialize} from "object-to-formdata";

const state = {
  interactions: [],
  interaction_field: [],
  interaction_save: [],
  fields: {},
  plans: {},
  activate_new_line: {},
  pacotes: {},
  troca_ddd: {},
  saveIsValid: false,
  emailList: [],
  checkNewLine: [],
  showNotification: false,
};

const actions = {
  async delete({ commit }, interaction_id) {
    try {
      const { data } = await axios.delete("/interaction/" + interaction_id);
      if (data.success === false) {
        throw new Error(data.message);
      }

    } catch (error) {
      throw new Error(error);
    }
  },
  async loadInteractions({ commit }, params) {
    try {
      const { data } = await axios.get("/interaction/", { params });
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_INTERACTION", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadInteractionFields({ commit }, params) {
    try {
      const { data } = await axios.get("interaction_type/fields", { params });
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_INTERACTION_FIELDS", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadEmails({ commit }, company) {
    try {
      const { data } = await axios.get("/solicitation/emails/" + company.id);
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_EMAILS", data.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async downloadFiles({ commit }, file) {
    try {
      axios
        .get("/interaction/download/" + file.id, {
          responseType: "blob"
        })
        .then(response => {
          const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileUrl;
          fileLink.setAttribute("download", file.name);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    } catch (error) {
      throw new Error(error);
    }
  },
  async saveInteraction({ commit, dispatch, state }, params) {
    try {
      const {
        fields: {anexo: files,...fields},
        emails,
        ...dados
      } = params;
      const fd = serialize({
        files,
        ...dados,
        fields,
        emails
      },{
        indices: true
      });

      const { data } = await axios.post("/interaction/", fd);
      if (data.success === false) {
        throw new Error(data.message);

      } else {
        await dispatch("loadInteractions", {
          solicitation: params.solicitation_id
        });
        state.fields = {};
        state.plans = {};
        state.pacotes = {};
        state.troca_ddd = {};
        commit("SET_SAVE", data);
        commit("RESET_INTERACTION_SOLICITATION_STATE");
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async loadCheckNewLine({ commit }, fields) {
    try {
      const { data } = await axios.post("/interaction/check-new-lines/", fields);
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_CHECK_NEW_LINE", data.message);
    } catch (error) {
      throw new Error(error);
    }
  },
  updateFields({ commit }, field) {
    commit("SET_FIELD", field);
  },
  updateFieldsPlans({ commit }, field) {
    commit("SET_FIELD_PLANS", field);
  },
  updateFieldsNewLine({ commit }, field) {
    commit("SET_FIELD_NEW_LINE", field);
  },
  updateFieldsPackages({ commit }, field) {
    commit("SET_FIELD_PACKAGES", field);
  },
  updateFieldsChangeDDD({ commit }, field) {
    commit("SET_FIELD_CHANGE_DDD", field);
  },
  setAutomationComponentFields({ commit }, values) {
    commit("SET_AUTOMATION_COMPONENT_FIELDS", values);
  },
  resetFields({ commit }, values) {
    commit("RESET_FIELDS", values);
  },
};

const mutations = {
  RESET_FIELDS(state) {
    state.fields = {};
  },
  RESET_INTERACTION_SOLICITATION_STATE(state) {
    state.activate_new_line = {};
  },
  SET_INTERACTION: (state, interactions) => {
    state.interactions = Object.assign({}, interactions);
  },
  SET_SHOW_NOTIFICATION: (state, data) => {
    state.showNotification = data;
  },
  SET_INTERACTION_FIELDS: (state, interaction_field) => {
    state.interaction_field = interaction_field;
  },
  SET_SAVE: (state, obj) => {
    state.interaction_save = obj;
  },
  SET_FIELD: (state, field) => {
    state.fields[field.name] = field.value;
  },
  SET_FIELD_PLANS: (state, plan) => {
    state.plans[plan.plan_name] = plan.fields;
  },
  SET_FIELD_NEW_LINE: (state, line) => {
    if (line.fields) {
      state.activate_new_line[line.plan_name] = line.fields;
    }
  },
  SET_FIELD_PACKAGES: (state, pacote) => {
    state.pacotes[pacote.package_name] = pacote.fields;
  },
  SET_FIELD_CHANGE_DDD: (state, troca_ddd) => {
    if (troca_ddd.name) {
      state.troca_ddd[troca_ddd.name] = troca_ddd.value;
    } else {
      state.troca_ddd = {};
    }
  },
  setSave: (state, valid) => {
    state.saveIsValid = valid;
  },
  SET_EMAILS: (state, list) => {
    state.emailList = list;
  },
  SET_CHECK_NEW_LINE: (state, data) => {
    state.checkNewLine = data;
  },
  SET_AUTOMATION_COMPONENT_FIELDS: (state, data) => {
    state.fields[data.automation] = data.data;
  }
};

const getters = {
  isLastInteractionFinal: (state) => state.interactions?.data?.[0]?.status_is_final ?? false
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
