<template>
  <div>
    <v-navigation-drawer
        app
        clipped
        v-model="drawer"
        :mini-variant.sync="mini"
        permanent
        class="menu"
        color="#2E3A59"
    >
      <v-list dark>
        <v-list-item v-if="logged_user.env === 'test'">
          <v-alert style="width: 100%; text-align: justify" dense type="warning" icon="mdi-alert-outline">
            Ambiente de testes
          </v-alert>
        </v-list-item>
        <v-list-item v-if="showButtonDialog">
          <button style="width: 100%; text-align: justify" @click="showDialogListPendencyConfiguration">
            <v-alert dense type="warning" icon="mdi-alert-outline">
              Configuração
            </v-alert>
          </button>
        </v-list-item>
        <v-list-item class="px-2" color="white">
          <v-list-item-avatar>
            <v-img src="/user.svg" alt="picture"></v-img>
          </v-list-item-avatar>

          <v-list-item-title>
            {{ logged_user.data.name }}
          </v-list-item-title>

          <v-btn icon @click.stop="mini = !mini">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>

      <v-divider class="grey lighten-2"></v-divider>

      <v-list dense dark>
        <v-list-item-group color="primary">
          <v-list-item
              v-for="(link, index) in menu_active.data"
              :key="index"
              :to="link.route"
              active-class="ativo"
              color="#fff"
          >
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <!--    <template v-slot:append>-->
      <!--      <div class="pa-2">-->
      <!--        <v-btn block>-->
      <!--          Sair-->
      <!--        </v-btn>-->
      <!--      </div>-->
      <!--    </template>-->
    </v-navigation-drawer>

    <ListPendencyConfigurationDialogComponent
      v-if="showDialog"
      @closeDialog="closeDialogListPendencyConfiguration"
    >
    </ListPendencyConfigurationDialogComponent>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import ListPendencyConfigurationDialogComponent from "../components/dialog/ListPendencyConfigurationDialogComponent.vue";
export default {
  name: "NavBar",
  components: {ListPendencyConfigurationDialogComponent},
  data() {
    return {
      drawer: true,
      mini: false,
      showDialog: false,
      showButtonDialog: false,
    };
  },
  computed: {
    ...mapState("menu", ["menu_active"]),
    ...mapState("user", ["logged_user"]),
    ...mapState("pendencyConfiguration", ["pendency_configuration_list"]),
  },
  async mounted() {
    if (this.logged_user && this.logged_user.data && this.logged_user.data.id_grupo && (this.logged_user.data.id_grupo === 1 || this.logged_user.data.id_grupo === 2)) {
      await this.loadPendencyConfiguration().catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
      if (this.pendency_configuration_list.length) {
        this.showButtonDialog = true;
      }
    }
  },
  methods: {
    ...mapActions('pendencyConfiguration', ['loadPendencyConfiguration']),
    showDialogListPendencyConfiguration() {
      this.showDialog = true;
    },
    closeDialogListPendencyConfiguration() {
      this.showDialog = false;
    },
  }
};
</script>

<style scoped>
.ativo {
  border-left: #fff solid 4px;
  color: #ffffff;
}
</style>
