import axios from "../axios.config";

const state = {
  ddd: {},
  changeTo: []
};

const actions = {
  async loadDDD({ commit }) {
    try {
      const { data } = await axios.get("/ddd");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_DDD", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadDDDByRegion({ commit }, params) {
    try {
      const { data } = await axios.get("/ddd/region", { params });
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_DDD", {data: data, index: params.index});
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadChangeTo({ commit }) {
    try {
      const { data } = await axios.get("/change_to");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_CHANGE_TO", data.data);
    } catch (error) {
      throw new Error(error);
    }
  }
};

const mutations = {
  SET_DDD: (state, value) => {
    if (value.index !== undefined) {
      state.ddd[value.index] = value.data.data;
    } else {
      state.ddd = value;
    }
  },

  SET_CHANGE_TO: (state, changeTo) => {
    state.changeTo = changeTo;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
