const state = {
  icons: [
    { name: "mdi-file" },
    { name: "mdi-cellphone" },
    { name: "mdi-phone" },
    { name: "mdi-shield-lock-outline" },
    { name: "mdi-chat" },
    { name: "mdi-web" },
    { name: "mdi-heart" },
    { name: "mdi-format-list-bulleted-square" },
    { name: "mdi-domain" },
    { name: "mdi-book" },
    { name: "mdi-television" },
    { name: "mdi-information" },
    { name: "mdi-folder" },
    { name: "mdi-clipboard-text" },
    { name: "mdi-gesture-tap-button" },
    { name: "mdi-cellphone-wireless" },
    { name: "mdi-account-switch-outline" },
    { name: "mdi-credit-card-wireless" },
    { name: "mdi-wifi-star" },
    { name: "mdi-wifi" },
    { name: "mdi-monitor-cellphone-star" },
    { name: "mdi-account-group" },
    { name: "mdi-account-tie" },
    { name: "mdi-android-messages" },
    { name: "mdi-archive" },
    { name: "mdi-headphones" }
  ]
};

export default {
  namespaced: true,
  state
};
