const state = {
  fields: []
};

const actions = {
  setField({ commit }, field) {
    commit("SET_FIELD_VALUE", field);
  }
};

const mutations = {
  SET_FIELD_VALUE: (state, field) => {
    state.fields = field;
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
