import packages from "./packages";
import {commit} from "lodash/seq";
import Vue from "vue";

const state = {
  wizard: {
    forms: [],
    step: 0,
    model: {},
    total: 0,
    plans: [],
    revision: []
  },
  form_email: {},
  errorMessage: []
};

const actions = {
  async loadUsers({ commit }, params) {
    commit("SET_MODEL", params);
  }
};
const mutations = {
  setForms: (state, forms) => {
    let n_model = {};
    for (let i in forms) {
      let item = forms[i];
      if (item.name === "roaming") {
        //n_model[item.name] = item;
      } else {
        n_model[item.name] = {};
        n_model[item.name].value = item.value ? item.value : "";
        if (item.name == "troca_ddd") {
          n_model[item.name].values = {};
        }
        if (item.values) {
          n_model[item.name].values = item.values;
        }
        n_model[item.name].text = item.text ? item.text : "";
      }
    }
    state.wizard.total = forms.length;
    state.wizard.forms = forms;
    state.wizard.model = n_model;
    state.wizard.step = 0;
    state.wizard.revision = [];
  },

  setStep: (state, step) => {
    state.wizard.step = step;
  },

  setErrorMessage: (state, message) => {
    state.errorMessage = message;
  },

  setModelRequester: (state, selected) => {
    let step = selected.index;
    let form = state.wizard.forms;
    let name = form[step].name;
    state.wizard.model[name].text = selected.usuario;
    state.wizard.model[name].value = selected.id;
  },

  setModelCompany: (state, value) => {
    let {step, selected} = value;
    let form = state.wizard.forms;
    let name = form[step].name;
    state.wizard.model[name].text = selected.nome;
    state.wizard.model[name].value = selected.value;
  },

  setModelProvider: (state, selected) => {
    let step = state.wizard.step;
    let form = state.wizard.forms;
    let name = form[step].name;
    state.wizard.model[name].text = selected.name;
    state.wizard.model[name].value = selected.id;
  },

  setModelPerson: (state, selected) => {
    let step = state.wizard.step;
    let form = state.wizard.forms;
    let name = form[step].name;
    state.wizard.model[name].text = selected.name;
    state.wizard.model[name].value = selected.value;
  },

  setModelPlans: (state, selected) => {
    let step = state.wizard.step;
    let form = state.wizard.forms;
    let name = form[step].name;
    state.wizard.model[name].text = selected.name;
    state.wizard.model[name].values = selected;
  },

  setModelActivateLine: (state, value) => {
    const {step, selected, type} = value;
    let form = state.wizard.forms;
    let name = form[step].name;
    const values = {
      text: selected.name,
      typeComponent: type,
      values: selected
    };
    Vue.set(state.wizard.model, name, values);
    state.wizard.revision[name] = selected;
  },

  setModelPackages: (state, value) => {
    const {step, selected} = value;
    let form = state.wizard.forms;
    let name = form[step].name;
    Vue.set(state.wizard.model, name, selected.model);
    Vue.set(state.wizard.revision, name, selected.revision);
  },

  setModelNewPackages: (state, value) => {
    const {step, selected} = value;
    let form = state.wizard.forms;
    let name = form[step].name;
    Vue.set(state.wizard.model, name, selected.model);
    Vue.set(state.wizard.revision, name, selected.revision);
  },

  removeModelNewPackages: (state, index) => {
    let step = state.wizard.step;
    let form = state.wizard.forms;
    let name = form[step].name;
    if (index > -1) {
      state.wizard.model[name].splice(index, 1);
    }
  },
  setModelRemovePackages: (state, value) => {
    const {step, selected} = value;
    let form = state.wizard.forms;
    let name = form[step].name;
    state.wizard.model[name] = [];
    state.wizard.model[name] = selected.model;
    state.wizard.revision[name] = selected.revision;
  },

  setModelReactivateLine: (state, value) => {
    const {step, selected} = value;
    let form = state.wizard.forms;
    let name = form[step].name;
    state.wizard.model[name] = [];
    state.wizard.model[name] = selected.model;
    state.wizard.revision[name] = selected.revision;
  },

  setModelVoluntaryLineSuspension: (state, value) => {
    const {step, selected} = value;
    const form = state.wizard.forms;
    const name = form[step].name;
    state.wizard.model[name] = [];
    state.wizard.model[name] = selected.model;
    state.wizard.revision[name] = selected.revision;
  },

  setModelCancelLine: (state, value) => {
    let {step, selected} = value;
    let form = state.wizard.forms;
    let name = form[step].name;
    state.wizard.model[name] = [];
    state.wizard.model[name] = selected;
    state.wizard.revision[name] = selected;
  },

  setModelLinha: (state, value) => {
    let {step, selected} = value;
    let form = state.wizard.forms;
    let name = form[step].name;
    state.wizard.model[name] = [];
    state.wizard.model[name] = selected;
    state.wizard.revision[name] = selected;
  },

  setModelUpdateOnlineManager: (state, selected) => {
    let step = state.wizard.step;
    let form = state.wizard.forms;
    let name = form[step].name;
    state.wizard.model[name] = [];
    state.wizard.model[name] = selected;
    state.wizard.revision[name] = selected;
  },

  setModelDDDChange: (state, value) => {
    const {step, params} = value;
    let form = state.wizard.forms;
    let name = form[step].name;
    state.wizard.model[name].values = {};
    state.wizard.model[name].values = params.value;
  },

  setModelSelectList: (state, value) => {
    const {step, selected} = value;
    let form = state.wizard.forms;
    let name = form[step].name;
    state.wizard.model[name].value = selected.value;
    state.wizard.model[name].text = selected.text;
  },

  setModelRadio: (state, value) => {
    const {step, text} = value;
    let form = state.wizard.forms;
    let name = form[step].name;
    state.wizard.model[name].value = text.value;
    state.wizard.model[name].text = text.text;
  },

  setModelText: (state, value)=> {
    const {step, text} = value;
    let form = state.wizard.forms;
    let name = form[step].name;
    state.wizard.model[name].value = text.value;
    state.wizard.model[name].text = text.text;
  },

  setModel: (state, selected) => {
    let step = state.wizard.step;
    let form = state.wizard.forms;
    let name = form[step].name;
    state.wizard.model[name].text = selected.name;
    state.wizard.model[name].value = selected.value;
  },

  SET_MODEL: (state, params) => {
    let step = state.wizard.step;
    let form = state.wizard.forms;
    let name = form[step].name;
    state.wizard.model[name].text = params.name;
    state.wizard.model[name].value = params.value;
  },

  SET_FORM_EMAIL: (state, params) => {
    state.form_email = params;
  },

  setPlans: (state, plans) => {
    return (state.wizard.plans = plans);
  }
};
const getters = {
  getModel: state => {
    return state.wizard.model;
  },
  getForms: state => {
    return state.wizard.forms;
  },
  getTotalSteps: state => {
    return state.wizard.total;
  },
  getItemComponent: state => {
    let step = state.wizard.step;
    let form = state.wizard.forms;
    let position = 0;
    let result = [];
    form.forEach(e => {
      e.component = e.type === "busca_pacote" ? e.component = "packagesComponent" : e.type + "Component" || "SelectListComponent";
      result[position] = e;
      position++;
    });

    return result;

    // if (form[step]) {
    //   let type = form[step].type;
    //   if (type === "busca_pacote") {
    //     return "packagesComponent";
    //   }
    //   return type + "Component" || "SelectListComponent";
    // } else {
    //   return "";
    // }
  },
  getCurrentItem: state => {
    return state.wizard.forms;
  },
  getItemType: state => {
    let step = state.wizard.step;
    let form = state.wizard.forms;
    if (form[step]) {
      return form[step].type;
    } else {
      return "text";
    }
  },
  getItemLabel: state => (step) => {
    let form = state.wizard.forms;
    if (form[step]) {
      return form[step].label;
    } else {
      return "";
    }
  },
  getItemText: state => (step) => {
    let form = state.wizard.forms;
    if (state.wizard.model && form[step]) {
      let model = state.wizard.model;
      let name = form[step].name;
      return model[name].text ? model[name].text : "";
    }
  },
  getItemValue: state => (step) => {
    let form = state.wizard.forms;

    if (state.wizard.model) {
      let model = state.wizard.model;
      if (form[step]) {
        let name = form[step].name;
        if (
          form[step].type === "plans" ||
          form[step].type === "activate_new_line"
        ) {
          return model[name].values && model[name].values.length > 0 ? model[name].values : "";
        }

        // Required verification ativar serviço
        if (form[step].type === "busca_pacote") {
          state.errorMessage = [];
          if (!model[name] || model[name].value === "") {
            state.errorMessage = {
              message: "Selecione um pacote."
            };
            return "";
          }

          let lineSelected = false;
          model[name].map(val => {
            lineSelected = false;
            if (val.line && val.line.id_linha) {
              lineSelected = true
            }

            if (lineSelected === false) {
              state.errorMessage = {
                message: "Selecione uma linha para o pacote " + val.descricao
              };
            }
          });

          if (state.errorMessage.message) {
            return "";
          } else {
            return model[name];
          }
        }

        // Required verification ROAMING
        if (form[step].type === "roaming") {
          state.errorMessage = [];
          if (!model[name] || model[name].length < 1) {
            state.errorMessage = {
              message: "Selecione um pacote."
            };
            return "";
          }
          let lineSelected = false;
          model[name].lines.map(val => {
            lineSelected = false;
            if (val.line && val.line.id_linha) {
              lineSelected = true
            }

            if (lineSelected === false) {
              state.errorMessage = {
                message: "Selecione uma linha para o pacote " + val.descricao
              };
            }
          });

          if (state.errorMessage.message) {
            return "";
          } else {
            return model[name];
          }
        }

        // Required verification Online Manager
        if (form[step].type === "update_gol") {
          let validationValue = "";
          model[name].map(val => {
            validationValue = val.fields[0].value;
          });
          return validationValue ? validationValue : "";
        }

        if (form[step].type === "voluntary_line_suspension" || form[step].type === "suspensao_perda_roubo") {
          state.errorMessage = [];

          if (model[name].lines.length < 1) {
            state.errorMessage = {
              message: "Selecione uma linha!"
            }

            return "";
          }
          if (model[name].lines) {
            model[name].lines.map(val => {
              if (val.line && !val.line.ddi) {
                state.errorMessage = {
                  message: "Selecione uma linha para todos os cards!"
                }
              }
            });
            if (state.errorMessage.message) {
              return "";
            } else {
              return model[name];
            }
          }
          return "";
        }

        // Verification Reativar Linha Suspensa
        if (form[step].type === "reactivate_line") {
          state.errorMessage = [];
          if (model[name].lines.length < 1) {
            state.errorMessage = {
              message: "Selecione uma linha!"
            }

            return "";
          }

          model[name].lines.map(val => {
            if (val.line && !val.line.ddi) {
              state.errorMessage = {
                message: "Selecione uma linha para todos os cards!"
              }
            }
          });
          if (state.errorMessage.message) {
            return "";
          } else {
            return model[name];
          }
          return "";
        }

        // Required verification Cancel Line && Suspend line
        if (
          form[step].type === "cancel_line" ||
          form[step].type === "suspend_line"
        ) {
          let validationValue = "";
          if (Array.isArray(model[name])) {
            model[name].map(val => {
              validationValue = val.value;
            });
            return validationValue ? validationValue : "";
          } else {
            return "";
          }
        }

        // Required verification Change Chip
        if (form[step].type === "change_chip") {
          return true;
        }

        // Required verification troca_ddd
        if (form[step].type === "troca_ddd" || form[step].type === "change_number_undue_divulgation") {
          let validationValue = "";
          state.errorMessage = [];
          if (form[step].type === "change_number_undue_divulgation") {
            if (!model[name].values) {
              state.errorMessage = {
                message: "O campo " + form[step].label + " é obrigatório."
              };

              return "";
            }
            model[name].values.map(val => {
              if (val.line.ddd !== val.ddd.code) {
                state.errorMessage = {
                  message: "DDD da linha " + (val.line.nr_linha ? val.line.nr_linha + " é diferente do novo DDD." : "")
                };
              }

              if (val.change_to !== 1) {
                state.errorMessage = {
                  message: "Campo 'Mudança para' da linha " + (val.line.nr_linha ? val.line.nr_linha + " está incorreto com o solicitado." : "")
                };
              }
            });
            if (state.errorMessage.message) {
              return "";
            }
          }

          if (!model[name].values) {
            state.errorMessage = {
              message: "O campo " + form[step].label + " é obrigatório."
            };
            return "";
          }

          model[name].values.map(val => {
            const line = val.line.ddi + " (" + val.line.ddd + ") " + val.line.nr_linha;
            if (val.change_to === "") {
              state.errorMessage = {
                message: "Selecione o campo 'Mudança para' na linha " + line + "."
              };
              return false;
            }

            if (val.new_ddd_code === "") {
              state.errorMessage = {
                message: "Selecione o campo 'Novo DDD' na linha " + line + "."
              };
            }
          });

          if (state.errorMessage.message) {
            return "";
          }

          return model[name];
        }

        // Required verification Line Disable Package
        if (form[step].type === "line_disable_package") {
          state.errorMessage = [];
          if (!model[name]['lines'] || model[name]['lines'].length < 1) {
            return "";
          }
          let packageTrue = false;
          model[name]['lines'].map(val => {
            packageTrue = false;
            val.packages.map(valPackage => {
              if (valPackage.desativar) {
                packageTrue = true;
              }
            });

            if (packageTrue === false) {
              state.errorMessage = {
                message: "Selecione pelo menos um pacote para a linha " + (val.line.nr_linha ? val.line.nr_linha : "")
              };
            }
          });

          if (state.errorMessage.message) {
            return "";
          } else {
            return model[name];
          }
        }

        // Required verification Change Chip
        if (form[step].type === "linha") {
          return model[name].id_linha ? model[name].id_linha : "";
        }

        return model[name].value ? model[name].value : "";
      } else {
        return "";
      }
    } else {
      return false;
    }
  },
  getErrorMessage: state => {
    return state.errorMessage;
  },
  getItemOptions: state => (step) => {
    let form = state.wizard.forms;
    if (state.wizard.model && form[step]) {
      let options = form[step].options;
      return options;
    }
  },
  getItemName: state => (step) => {
    let form = state.wizard.forms;
    if (form[step]) {
      let name = form[step].name;
      return name;
    } else {
      return "";
    }
  },
  getStep: state => {
    return state.wizard.step;
  },
  getPlans: state => {
    return state.wizard.plans;
  }
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
