import axios from "../axios.config";

const state = {
  allPackages: [],
  packages: [],
  lines: [],
  suspend_lines: [],
  line_reference: [],
  linePackages: [],
  packageTypes: []
};

const actions = {
  async loadingFilters({ commit }, params) {
    try {
      commit("SET_PACKAGE_TYPES", []);
      commit("SET_PACKAGES", []);
      commit("SET_LINES", []);
      const { data } = await axios.get("/type_packages", params);
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_PACKAGE_TYPES", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadAllPackages({ commit }) {
    try {
      const { data } = await axios.get("/packages/");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_ALL_PACKAGES", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadPackages({ commit }, params) {
    try {
      commit("SET_LINES", []);
      const { data } = await axios.get("/packages/", {
        params
      });
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_PACKAGES", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadLines({ commit }, params) {
    try {
      commit("SET_LINES", []);
      const { data } = await axios.get("/lines/", {
        params
      });
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_LINES", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadLineReference({ commit }, params) {
    try {
      commit("SET_LINE_REFERENCE", []);
      const { data } = await axios.get("/packages/line-reference", {
        params
      });
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_LINE_REFERENCE", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadSuspendLines({ commit }) {
    try {
      commit("SET_LINES", []);
      const { data } = await axios.get("/lines?search_text=suspend");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_LINES", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadLinePackages({ commit }, params) {
    if (params) {
      let parametros = {};
      if (params.id_linha) {
        parametros.line = params.id_linha;
      }
      if (params.type) {
        parametros.type = params.type;
      }
      try {
        const { data } = await axios.get("/line_packages", {
          params: parametros
        });
        if (data.success === false) {
          throw new Error(data.message);
        }
        commit("SET_LINE_PACKAGES", {data: data, params: parametros});
      } catch (error) {
        throw new Error(error);
      }
    } else {
      commit("SET_LINE_PACKAGES", []);
    }
  }
};

const mutations = {
  SET_PACKAGE_TYPES: (state, packageTypes) => {
    state.packageTypes = packageTypes;
  },
  SET_PACKAGES: (state, packages) => {
    state.packages = packages;
  },
  SET_ALL_PACKAGES: (state, packages) => {
    state.allPackages = packages;
  },
  SET_LINES: (state, lines) => {
    state.lines = lines;
  },
  SET_LINE_REFERENCE: (state, line) => {
    state.line_reference = line;
  },
  SET_SUSPEND_LINES: (state, lines) => {
    state.suspend_lines = lines;
  },
  SET_LINE_PACKAGES: (state, linesPackages) => {
    state.linePackages = linesPackages;
  }
};

const getters = {
  getAllPackages: state => {
    if (state.allPackages.data) {
      return state.allPackages.data;
    }
  },
  getPackages: state => {
    if (state.packages.data) {
      return state.packages.data;
    }
  },
  getLinePackages: state => {
    if (state.linePackages.data) {
      return state.linePackages.data;
    }
  },
  getPackageTypes: state => {
    if (state.packageTypes.data) {
      return state.packageTypes.data;
    }
  },
  getLines: state => {
    if (state.lines.data) {
      return state.lines.data;
    }
  },
  getLinesSuspend: state => {
    if (state.suspend_lines.data) {
      return state.suspend_lines.data;
    }
  },
  getLineReference: state => {
    if (state.line_reference.data) {
      return state.line_reference.data;
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
