import axios from "../axios.config";

const state = {
  accounts: []
};

const actions = {
  async loadAccount({ commit }) {
    try {
      const { data } = await axios.get("/account/");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_ACCOUNT_LIST", data);
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
};

const mutations = {
  SET_ACCOUNT_LIST: (state, data) => {
    state.accounts = data.data;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
