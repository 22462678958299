import axios from "@/axios.config";

const state = {
  menu: [],
  menu_active: [],
  menu_save: [],
  selectedMenu: []
};

const actions = {
  async loadMenu({ commit }) {
    try {
      const { data } = await axios.get("/menu");
      if (!data.success) {
        throw new Error(data.message);
      }
      commit("SET_MENU", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadActiveMenu({ commit }) {
    try {
      const { data } = await axios.get("/menu", { params: { active: true } });
      if (!data.success) {
        throw new Error(data.message);
      }
      commit("SET_ACTIVE_MENU", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadSelectedMenu({ commit }, id) {
    try {
      const { data } = await axios.get("/menu/" + id);
      if (!data.success) {
        throw new Error(data.message);
      }
      commit("SET_SELECTED_MENU", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async createMenuItem({ commit, dispatch }, params) {
    try {
      const { data } = await axios.post("/menu", params);
      if (data.success) {
        dispatch("loadMenu");
        dispatch("loadActiveMenu");
      } else {
        throw new Error(data.message);
      }
      commit("SET_SAVE", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async updateMenuItem({ commit, dispatch }, params) {
    try {
      const { data } = await axios.patch("/menu/" + params.id, params);
      if (data.success) {
        dispatch("loadMenu");
        dispatch("loadActiveMenu");
      } else {
        throw new Error(data.message);
      }
      commit("SET_SAVE", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async deleteMenu({ dispatch }, id) {
    try {
      const { data } = await axios.delete("/menu/" + id);
      if (!data.success) {
        throw new Error(data.message);
      }
      dispatch("loadMenu");
      dispatch("loadActiveMenu");
    } catch (error) {
      throw new Error(error);
    }
  }
};

const mutations = {
  SET_MENU: (state, menu) => {
    state.menu = menu;
  },
  SET_ACTIVE_MENU: (state, menu) => {
    state.menu_active = menu;
  },
  SET_SELECTED_MENU: (stete, menuItem) => {
    state.selectedMenu = menuItem;
  },
  SET_SAVE: (state, obj) => {
    state.menu_save = obj;
  }
};

export default {
  namespaced: true,
  actions,
  state,
  mutations
};
