import axios from "../axios.config";

const state = {
  categories: [],
  associatedCategories: [],
  categoriesToSave: [],
  category_save: [],
  associated_categories_group: [],
  categories_list: [],
  category_to_group: [],
  category_update: [],
  selected_category: []
};

const actions = {
  async loadCategories({ commit }, params) {
    try {
      const { data } = await axios.get("/solicitation_type/category", {
        params
      });
      if (!data.success) {
        throw new Error(data.message);
      }
      commit("setCategories", data.data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadCategoriesList({ commit }) {
    try {
      const { data } = await axios.get("/category/");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_CATEGORIES_LIST", data.data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadAssociatedCategories({ commit }, params) {
    try {
      const { data } = await axios.get("/solicitation_type/" + params.id);
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("setAssociatedCategories", data.data[0].content.categories);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadCategoryToGroup({ commit }, group) {
    try {
      const { data } = await axios.get(`/category?group=${group.id}`);
      commit("SET_CATEGORY_TO_GROUP", data.data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadSelectedCategory({ commit }, category) {
    try {
      const { data } = await axios.get(`/category/${category.id}`);
      commit("SET_SELECTED_CATEGORY", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async saveNewCategory({ commit, dispatch }, params) {
    try {
      const { data } = await axios.post("/category/", params);
      if (data.success) {
        dispatch("loadCategoriesList");
      }
      commit("SET_NEW_CATEGORIES", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async updateCategory({ commit, dispatch }, category) {
    try {
      const { data } = await axios.patch(`/category/${category.id}`, category);
      if (data.success) {
        dispatch("loadCategoriesList", { category: category });
      }
      commit("SET_UPDATE", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async associatedCategoryToGroup({ commit, dispatch }, params) {
    try {
      const { data } = await axios.post("/category/associate_group", params);
      await dispatch("loadCategoryToGroup", { id: params.group_id });
      commit("SET_ASSOCIATED_CATEGORY_TO_GROUP", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async disassociateCategoryToGroup({ dispatch }, params) {
    try {
      const { data } = await axios.post("/category/disassociate_group", params);
      if (data.success) {
        await dispatch("loadCategoryToGroup", { id: params.group_id });
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  async deleteCategoryToGroup({ dispatch }, params) {
    try {
      const { data } = await axios.delete(`/category/${params.category.id}`);
      if (data.success) {
        await dispatch("loadCategoriesList", { category: params.category });
        await dispatch("loadCategoryToGroup", { id: params.group_id });
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  async deleteCategory({ dispatch }, category) {
    try {
      const { data } = await axios.delete(`/category/${category.id}`);
      if (data.success) {
        await dispatch("loadCategoriesList", { category: category });
      }
    } catch (error) {
      throw new Error(error);
    }
  }
};
const mutations = {
  setCategories: (state, categories) => {
    state.categories = categories;
  },
  setAssociatedCategories: (state, categories) => {
    state.associatedCategories = categories;
  },
  setCategoriesToSave: (state, categories) => {
    state.categoriesToSave = categories;
  },
  SET_NEW_CATEGORIES: (state, obj) => {
    state.category_save = obj;
  },
  SET_ASSOCIATED_CATEGORY_TO_GROUP: (state, obj) => {
    state.associated_categories_group = obj;
  },
  SET_CATEGORY_TO_GROUP: (state, categoryToGroup) => {
    state.category_to_group = categoryToGroup;
  },
  SET_CATEGORIES_LIST: (state, categories_list) => {
    state.categories_list = categories_list;
  },
  SET_SELECTED_CATEGORY: (state, selectedCategory) => {
    state.selected_category = selectedCategory;
  },
  SET_UPDATE: (state, obj) => {
    state.category_update = obj;
  }
};

const getters = {
  getSelectedCategory: state => {
    if (state.selected_category.data) {
      return { ...state.selected_category.data[0] };
    }
  }
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
