import Vue from "vue";
import VueRouter from "vue-router";
import Token from "../helpers/token.helper";
import axios from "@/axios.config";
import Swal from "sweetalert2";
import store from "@/store";
// import store from "../store";
Vue.use(VueRouter);

function isLoggedIn(to, from, next) {
  if (Token.hasToken()) {
    next();
  } else {
    next({ name: "notFound" });
  }
}

// async function canEnter(to, from, next) {
//   if (isLoggedIn) {
//     await store.dispatch("configurationCompany/loadUserGroup").catch(error => {
//       this.$swal("Oops..", error.message, "error");
//     });
//     if (store.state.configurationCompany.user_group.group === 1) {
//       next();
//     } else {
//       next({ name: "home" });
//     }
//   } else {
//     next({ name: "login" });
//   }
// }

const routes = [
  {
    path: "/",
    redirect: "/solicitation",
    beforeEnter: isLoggedIn
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/logout",
    name: "logout",
    beforeEnter(to, from, next) {
      Token.setToken();
      next({ name: "login" });
    }
  },
  {
    path: "/token/:token",
    name: "token",
    async beforeEnter(to, from, next) {
      const token = to.params.token;
      Token.setToken(token);
      let swalInstance = false;
      try {
        // Função para verificar o status a cada 5 segundos
        const checkStatus = async () => {
          try {
            const response = await axios.post("/check_setup_company", { token });
            if (response.data.success && response.data.status === 'running') {
              if (!swalInstance) {
                swalInstance = true;
                await Swal.fire({
                  title: 'Aguarde',
                  text: 'Estamos finalizando o setup. Por favor, aguarde...',
                  icon: 'info',
                  allowOutsideClick: false,
                  showConfirmButton: false,
                });
              }
            } else if (!response.data.success) {
              clearInterval(intervalId);
              await Swal.close();
              await Swal.fire('Opss...', 'Ocorreu um erro. Contate o suporte.', 'warning');
              next(false);
            } else {
              clearInterval(intervalId);
              if (swalInstance) {
                await Swal.close();
                await Swal.fire({
                  title: 'Concluído!',
                  html: 'O setup da sua empresa foi concluído com sucesso.<br>Em caso de dúvidas, entre em contato com nossa equipe de suporte.',
                  icon: 'success',
                });
              }
              store.commit('localAuth/SET_CHECK_TOKENS', true);
              next({ name: "solicitation" });
            }
          } catch (error) {
            clearInterval(intervalId);
            await Swal.close();
            await Swal.fire('Opss...', 'Ocorreu um erro. Contate o suporte.', 'warning');
            next({ name: "notFound" });
          }
        };
        // intervalo de 5 segundos
        const intervalId = setInterval(checkStatus, 5000);
        await checkStatus();
      } catch (error) {
        await Swal.fire('Opss...', 'Ocorreu um erro. Contate o suporte.', 'warning');
        next({ name: "notFound" });
      }
    }
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/Dashboard"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/solicitation/group",
    name: "solicitation-group",
    component: () => import("@/views/solicitation/SolicitationGroup"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/solicitation/tag",
    name: "solicitation-tag",
    component: () => import("@/views/solicitation/SolicitationTag"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/solicitation/type/:id",
    name: "solicitation-type",
    component: () => import("@/views/solicitation/SolicitationType"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/solicitation/wizard/:id",
    name: "solicitation-wizard",
    component: () => import("@/views/solicitation/SolicitationWizard"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/solicitation/:id",
    name: "solicitation-id",
    component: () => import("@/views/solicitation/SolicitationDetail"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/solicitation",
    name: "solicitation",
    component: () => import("@/views/solicitation/SolicitationList"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/solicitation_type",
    name: "solicitation-type-list",
    component: () => import("@/views/solicitationType/SolicitationTypeList"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/solicitation_type/new",
    name: "solicitation-type-new",
    component: () => import("@/views/solicitationType/SolicitationTypeNew"),
    beforeEnter: isLoggedIn
  },

  {
    path: "/solicitation_type/edit/:id",
    name: "solicitation-type-edit",
    component: () => import("@/views/solicitationType/SolicitationTypeEdit"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/interactions/:id",
    name: "interactions",
    component: () => import("@/views/interactions/InteractionList"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/approval/:solicitation_id",
    name: "approval",
    component: () => import("@/views/Approval"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/validate/:solicitation_id",
    name: "validate",
    component: () => import("@/views/Validate"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/provider/",
    name: "provider-list",
    component: () => import("@/views/provider/ProviderList"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/provider/new",
    name: "provider-new",
    component: () => import("@/views/provider/ProviderNew"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/provider/:id",
    name: "provider-edit",
    component: () => import("@/views/provider/ProviderEdit"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/tag",
    name: "tag-list",
    component: () => import("@/views/tag/TagList"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/tag/new",
    name: "tag-new",
    component: () => import("@/views/tag/TagNew"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/tag/:id",
    name: "tag-edit",
    component: () => import("@/views/tag/TagEdit"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/category",
    name: "category-list",
    component: () => import("@/views/category/CategoryList"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/category/new",
    name: "category-new",
    component: () => import("@/views/category/CategoryNew"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/category/:id",
    name: "category-edit",
    component: () => import("@/views/category/CategoryEdit"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/email-sync",
    name: "email-sync",
    component: () => import("@/views/emailSync/EmailSync"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/configuration_company/",
    name: "configuration-company",
    component: () =>
      import("@/views/configurationCompany/ConfigurationCompany"),
    beforeEnter: isLoggedIn
    //beforeEnter: canEnter
  },
  {
    path: "/configuration_company/menu/new",
    name: "menu-new",
    component: () => import("@/views/configurationCompany/menuTab/MenuTabNew"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/configuration_company/menu/:id",
    name: "menu-edit",
    component: () => import("@/views/configurationCompany/menuTab/MenuTabEdit"),
    beforeEnter: isLoggedIn
  },
  {
    path: "/404",
    alias: "*",
    name: "notFound",
    component: () =>
      import(
        /* webpackChunkName: "NotFound" */
        "@/views/NotFound"
      )
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
