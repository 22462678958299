<template>
  <v-app-bar app clipped-left short elevation="1" color="#fff">
    <v-toolbar-title>
      <router-link id="logo" :to="{ name: 'dashboard' }">
        <v-row align="center">
          <v-col cols="2" class="pr-1">
            <v-img src="@/assets/logo_join.svg" alt="logo"></v-img>
          </v-col>
          <v-col cols="6">
            <strong>JOIN Conecta</strong>
          </v-col>
        </v-row>
      </router-link>
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
export default {
  name: "TopBar",
  props: ["user"]
};
</script>

<style scoped>
#logo {
  text-decoration: none;
  color: #e7505a;
}
</style>
