import axios from "@/axios.config";

const state = {
  users: [],
  logged_user: {},
  user_join: {}
};

const actions = {
  async loadUsers({ commit }) {
    try {
      const { data } = await axios.get("/user/");
      if (!data.success) {
        throw new Error(data.message);
      }
      commit("SET_USER", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadLoggedUser({ commit }) {
    try {
      const { data } = await axios.get("/logged_user");
      if (!data.success) {
        throw new Error(data.message);
      }
      commit("SET_LOGGED_USER", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async saveUserJoin({ commit, dispatch }, params) {
    try {
      const { data } = await axios.post("/user/save-user-join", params);
      if (data.success) {
        commit("SET_SAVE_USER_JOIN", data);
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
};

const mutations = {
  SET_USER: (state, users) => {
    state.users = users;
  },
  SET_LOGGED_USER: (state, user) => {
    state.logged_user = user;
  },
  SET_SAVE_USER_JOIN: (state, data) => {
    state.user_join = data.data;
  }
};

export default {
  namespaced: true,
  actions,
  state,
  mutations
};
