import router from "@/router";

const state = {
  check_setup: false,
};

const actions = {
  async login({ dispatch }, { valid, user }) {
    if (valid) {
      dispatch(
        "auth/authenticate",
        {
          strategy: "local",
          ...user
        },
        { root: true }
      )
        .then(async () => {
          router.push("/dashboard");
        })
        .catch(() => {
          return "Erro de login";
        });
    }
  },
  // signUp(_, { valid, user }) {
  //   if (valid) {
  //     const newUser = new models.api.User(user);
  //     newUser.save().then(() => {
  //       router.push("/");
  //     });
  //   }
  // }
};
const mutations = {
  SET_CHECK_TOKENS: (state, value) => {
    state.check_setup = value;
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
