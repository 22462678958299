import axios from "axios";
import Token from "./helpers/token.helper";
const yodaURL = process.env.VUE_APP_API_URL;

const axiosConfig = axios.create({
  baseURL: yodaURL
});
axiosConfig.interceptors.request.use(
  function(config) {
    if (Token.hasToken()) {
      config.headers.Authorization = Token.getToken();
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosConfig.interceptors.response.use(
  response => response,
  error => {
    /*
     * aqui se pode ter um gerenciamento de erros centralizado. No exemplo abaixo estou
     * executando uma action na Vuex store para guardar a mensagem de erro no state e
     * então a mensagem poderá ser consumida por qualquer componente.
     */
    return Promise.reject(error);
  }
);

export default axiosConfig;
