import axios from "../axios.config";
const state = {
  group_save: [],
  group_update: [],
  group_list: [],
  selected_group: {}
};

const actions = {
  async loadGroup({ commit }) {
    try {
      const { data } = await axios.get("/group");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_GROUP_LIST", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadSelectedGroup({ commit }, group) {
    try {
      const { data } = await axios.get(`/group/${group.id}`);
      commit("SET_SELECTED_GROUP", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async saveGroup({ commit, dispatch }, params) {
    try {
      const { data } = await axios.post("/group/", params);
      if (data.success) {
        dispatch("loadGroup", { group: params });
      }
      commit("SET_SAVE", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async updateGroup({ commit, dispatch }, group) {
    try {
      const { data } = await axios.patch(`/group/${group.id}`, group);
      if (data.success) {
        dispatch("loadGroup", { group: group });
      }
      commit("SET_UPDATE", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async deleteGroup({ dispatch }, group) {
    try {
      const { data } = await axios.delete(`/group/${group.id}`);
      if (data.success) {
        await dispatch("loadGroup", { group: group });
      }
    } catch (error) {
      throw new Error(error);
    }
  }
};

const mutations = {
  SET_GROUP_LIST: (state, group_list) => {
    state.group_list = group_list.data;
  },

  SET_SAVE: (state, obj) => {
    state.group_save = obj;
  },

  SET_UPDATE: (state, obj) => {
    state.group_update = obj;
  },

  SET_SELECTED_GROUP: (state, selectedGroup) => {
    state.selected_group = selectedGroup;
  }
};

const getters = {
  getSelectedGroup: state => {
    if (state.selected_group.data) {
      return { ...state.selected_group.data[0] };
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
