import axios from "@/axios.config";

const state = {
  user_group: []
};

const actions = {
  async loadUserGroup({ commit }) {
    try {
      const { data } = await axios.get("/user_group");
      if (!data.success) {
        throw new Error(data.message);
      }
      commit("SET_USER_GROUP", data);
    } catch (error) {
      throw new Error(error);
    }
  }
};

const mutations = {
  SET_USER_GROUP: (state, user_group) => {
    state.user_group = user_group;
  }
};

export default {
  namespaced: true,
  actions,
  state,
  mutations
};
