import axios from "../axios.config";
const state = {
  new: true,
  solicitation_type: {},
  groups: [],
  solicitationType_save: [],
  solicitationType_update: [],
  solicitationType_list: [],
  default_solicitation_type_list: [],
  selected_solicitation_type: [],
  solicitation_type_response: {
    errors: "",
    success: "",
    dialog: false
  },
  solicitation_type_field_types: [],
  field_types: [],
  fields: [],
  fields_required: [],
  limit_Approval: [],
  user_groups: [],
  userGroupsAssociate: [],
  associatedUserGroups: [],
  list_solicitation_type_tag: [],
  loadingList: false,
};

const actions = {
  async loadSolicitationTypes({ commit }, params) {
    try {
      const { data } = await axios.get("/solicitation_type", { params });
      if (!data.success) {
        throw new Error(data.message);
      }
      commit("SET_SOLICITATION_TYPES", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadGroups({ commit }) {
    try {
      const { data } = await axios.get("/solicitation_type/group/");
      if (!data.success) {
        throw new Error(data.message);
      }
      commit("SET_GROUPS", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  changeSolicitationTypeNewOrEdit({ commit }, status) {
    status !== "" ? (status = false) : (status = true);
    commit("SET_SOLICITATION_TYPE_NEW_OR_EDIT", status);
  },

  async loadSelectedSolicitationType({ commit }, id) {
    try {
      const { data } = await axios.get("/solicitation_type/" + id.id);
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_SELECTED_SOLICITATION_TYPE", data);
      commit("setFields", data.data.fields);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadFieldTypes({ commit }) {
    try {
      const { data } = await axios.get("/fields/types/");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_FIELD_TYPES", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadDefaultSolicitationType({ commit }) {
    try {
      const { data } = await axios.get("/solicitation_type/default-list");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_DEFAULT_SOLICITATION_TYPE_LIST", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async updateSolicitationType({ commit, dispatch }, params) {
    try {
      const { data } = await axios.patch(
        `/solicitation_type/${params.solicitation_type}`,
        params
      );
      if (data.success) {
        dispatch("loadSolicitationTypes", {
          solicitationType: params.solicitation_type
        });
      }
      commit("SET_UPDATE", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async updateSolicitationTypeRules({ commit, dispatch }, params) {
    try {
      const { data } = await axios.post(
          `/solicitation_type/rules/${params.solicitation_type_id}`,
          params
      );
      if (data.success) {
        dispatch("loadSolicitationTypes", {
          solicitationType: params.solicitation_type
        });
      }
      commit("SET_UPDATE", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async saveSolicitationType({ commit, dispatch }, params) {
    try {
      const { data } = await axios.post("/solicitation_type/", params);
      if (data.success) {
        dispatch("loadSolicitationTypeNew", { solicitationType: params });
      }
      commit("SET_SAVE", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadSolicitationTypeNew({ commit }) {
    let solicitation_type = {
      display_name: "",
      description: "",
      sla_validate_days: "",
      sla_validate_hours: "",
      sla_validate_minutes: "",
      sla_finish_days: "",
      sla_finish_hours: "",
      provider_id: "",
      fields: [],
      fields_required: []
    };
    commit("setFields", solicitation_type.fields);
  },

  async loadSolicitationTypeList({ commit }) {
    try {
      const { data } = await axios.get("/solicitation_type/");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_SOLICITATION_TYPE_LIST", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async deleteSolicitationType({ dispatch }, params) {
    try {
      const { data } = await axios.delete(`/solicitation_type/${params.id}`);
      if (data.success) {
        await dispatch("loadSolicitationTypeList", {
          solicitationType: params.solicitation_type
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadUserGroup({ commit }) {
    try {
      const { data } = await axios.get("/user_group");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_USER_GROUP", data.data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadUserGroupAssociated({ commit }, id) {
    try {
      const { data } = await axios.get("/solicitation_type/" + id);
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_ASSOCIATED_USER_GROUPS", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async associateUserGroups({ commit }, params) {
    try {
      const {
        data
      } = await axios.patch(
        `/solicitation_type/link_user_groups/${params.solicitationType_id}`,
        { user_groups: params.user_groups }
      );
      commit("SET_ASSOCIATION_RESULT", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadSolicitationTags({ commit }, params) {
    try {
      commit("SET_LOADING_LIST", true);
      const { data } = await axios.patch(`/solicitation_type/list-solicitation-tags`, {data: params});
      commit("SET_LIST_SOLICITATION_TYPE_LIST_TAG", data);
      commit("SET_LOADING_LIST", false);
    } catch (error) {
      throw new Error(error);
    }
  }
};
const mutations = {
  SET_SOLICITATION_TYPES: (state, solicitationTypes) => {
    state.solicitation_type = solicitationTypes.data;
  },

  SET_DEFAULT_SOLICITATION_TYPE_LIST: (state, data) => {
    state.default_solicitation_type_list = data.data;
  },

  SET_GROUPS: (state, groups) => {
    state.groups = groups;
  },

  SET_LIST_SOLICITATION_TYPE_LIST_TAG: (state, data) => {
    state.list_solicitation_type_tag = data;
  },

  SET_LOADING_LIST: (state, data) => {
    state.loadingList = data;
  },

  SET_TAG_LIST: (state, tagList) => {
    state.tag_list = tagList;
  },

  SET_SOLICITATION_TYPE_LIST: (state, solicitationType_list) => {
    state.solicitationType_list = solicitationType_list;
  },

  SET_UPDATE: (state, obj) => {
    state.solicitationType_update = obj;
  },

  SET_SOLICITATION_TYPE_NEW_OR_EDIT: (state, status) => {
    state.new = status;
  },

  SET_SAVE: (state, obj) => {
    state.solicitationType_save = obj;
  },

  SET_SELECTED_SOLICITATION_TYPE: (state, selected_solicitation_type) => {
    state.selected_solicitation_type = selected_solicitation_type;
  },

  SET_SELECTED_SOLICITATION_TYPE_UPDATE: (
    state,
    selected_solicitation_type_update
  ) => {
    state.selected_solicitation_type_update = selected_solicitation_type_update;
  },

  setFields: (state, fields) => {
    state.fields = fields;
  },

  setFieldsRequired: (state, fields_required) => {
    state.fields_required = fields_required;
  },

  SET_FIELD_TYPES: (state, fieldtypes) => {
    state.field_types = fieldtypes;
  },

  SET_USER_GROUP: (state, group) => {
    state.user_groups = group;
  },

  SET_ASSOCIATED_USER_GROUPS: (state, group) => {
    state.associatedUserGroups = group;
  },

  SET_ASSOCIATION_RESULT: (state, result) => {
    state.userGroupsAssociate = result;
  }
};
const getters = {
  getSolicitationTypeId: state => {
    return state.solicitationType_save.data[0].id;
  },

  getFieldTypesNew: state => {
    return state.field_types.data;
  },

  getFieldTypes: state => {
    return [...state.selected_solicitation_type.data.field_types];
  },

  getProvider: state => {
    return state.selected_solicitation_type.data.provider_id;
  },

  getFields: state => {
    return state.fields;
  },

  getFieldsRequired: state => {
    return state.fields_required;
  },

  getApprovers: state => {
    return [...state.approvers_options];
  },

  getTypes: state => {
    return [...state.approval_options.data[0].types];
  }
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
