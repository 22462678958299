import axios from "../axios.config";

const state = {
  approval_options: [],
  approvers_options: [],
  approval_rules: [],
  validator_rules: [],
  approvers: [],
  validators: [],
  solicitationTypeApprovalRules: [],
  solicitationTypeValidatorRules: []
};

const actions = {
  async loadApprovalOptions({ commit }) {
    try {
      const { data } = await axios.get("/approval/approval_options/");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_APPROVAL_OPTIONS", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadApprovers({ commit }) {
    try {
      const { data } = await axios.get("/user/");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_APPROVERS_OPTIONS", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadApprovalRules({ commit }, params) {
    try {
      const { data } = await axios.get(
        "approval/approval_rules?solicitation_type=" + params.id
      );
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("setSolicitationTypeApprovalRules", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadValidatorsRules({ commit }, params) {
    try {
      const { data } = await axios.get(
          "approval/validator_rules/" + params.id
      );
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("setSolicitationTypeValidatorRules", data);
    } catch (error) {
      throw new Error(error);
    }
  }
};

const mutations = {
  SET_APPROVAL_OPTIONS: (state, approvalOptions) => {
    state.approval_options = approvalOptions.data[0];
  },

  SET_APPROVERS_OPTIONS: (state, approversOptions) => {
    state.approvers_options = approversOptions.data;
  },

  setApprovalRules: (state, rules) => {
    state.approval_rules = rules;
  },

  setValidatorRules: (state, rules) => {
    state.validator_rules = rules;
  },

  setApprovers: (state, approver) => {
    state.approvers = approver;
  },

  setValidators: (state, data) => {
    state.validators = data;
  },

  setSolicitationTypeApprovalRules: (state, approver) => {
    state.solicitationTypeApprovalRules = approver.data;
  },

  setSolicitationTypeValidatorRules: (state, data) => {
    state.solicitationTypeValidatorRules = data.data;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
