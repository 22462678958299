import axios from "../axios.config";

const state = {
  situationList: [],
  typeList: []
};

const actions = {
  async loadblockSituation({ commit }) {
    try {
      const { data } = await axios.get("/block_situation/");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_BLOCK_SITUATION", data.data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadBlockType({ commit }) {
    try {
      const { data } = await axios.get("/block_type/");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_BLOCK_TYPE", data.data);
    } catch (error) {
      throw new Error(error);
    }
  }
};

const mutations = {
  SET_BLOCK_SITUATION: (state, situationRequest) => {
    state.situationList = situationRequest;
  },

  SET_BLOCK_TYPE: (state, type) => {
    state.typeList = type;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
