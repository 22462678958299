<template>
  <v-app>
    <top-bar v-if="isAuthenticated" />
    <nav-bar v-if="isAuthenticated && !loading" :logout="logout" />
    <v-main class="mt-2 backgroud">
      <v-container fluid class="mt-0 pt-0">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Token from "@/helpers/token.helper";
import TopBar from "./layouts/TopBar";
import NavBar from "./layouts/NavBar";
import { mapActions, mapState } from "vuex";
import store from "@/store";
export default {
  name: "App",
  components: {
    TopBar,
    NavBar
  },
  data() {
    return {
      drawer: false,
      loading: true
    };
  },
  async mounted() {
    this.loading = false;
  },
  computed: {
    isAuthenticated() {
      return Token.hasToken();
    },
    ...mapState('localAuth', ['check_setup']),
  },
  methods: {
    ...mapActions("menu", ["loadActiveMenu"]),
    ...mapActions("user", ["loadLoggedUser"]),
    logout() {
      this.$router.push("/login");
    }
  },
  watch: {
    check_setup: {
      deep: true,
      handler(val) {
        if (val) {
          this.loading = true;
          this.loadActiveMenu().catch((error) => {
            this.$swal("Oops..", error.message, "error");
          });
          this.loadLoggedUser().catch((error) => {
            this.$swal("Oops..", error.message, "error");
          });
          this.loading = false;
        }
      }
    },
    "$route.path": {
      deep: true,
      immediate: true,
      handler(newPath, oldPath) {
        if (newPath != '/' && this.check_setup == false) {
          this.loading = true;
          try {
            Promise.all([
              this.loadActiveMenu(),
              this.loadLoggedUser()
            ])
          } catch (e) {
            this.$swal("Oops..", e.response.data.message, "error");
          }
          store.commit('localAuth/SET_CHECK_TOKENS', true);
          this.loading = false;
        }
      }
    }
  }
};
</script>

<style>
/*.primary_btn {*/
/*  background: linear-gradient(177.98deg, #f5796a 1.7%, #ef3f3a 122.46%);*/
/*}*/
</style>
