import Vue from "vue";
import Vuetify from "vuetify/lib";
import pt from "vuetify/es5/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { pt },
    current: "pt"
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#4D638C",
        secondary: "#5F687E",
        backgroud: "#E4E9F2"
      }
    }
  }
});
