import axios from "../axios.config";

const state = {
  pendency_configuration_list: []
};

const actions = {
  async loadPendencyConfiguration({ commit }) {
    try {
      commit("SET_PENDENCY_CONFIGURATION", []);
      const { data } = await axios.get("/pendency-configuration/");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_PENDENCY_CONFIGURATION", data.data);
    } catch (error) {
      throw new Error(error);
    }
  }
};

const mutations = {
  SET_PENDENCY_CONFIGURATION: (state, automation_list) => {
    state.pendency_configuration_list = automation_list;
  }
};

const getters = {
  getPendencyConfiguration: state => {
    return [...state.pendency_configuration_list];
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
