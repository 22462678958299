import axios from "../axios.config";

const state = {
  plans: [],
  ddd: [],
  account_line: []
};

const actions = {
  async loadPlans({ commit }) {
    try {
      const { data } = await axios.get("/plan/");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_PLANS", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadDDD({ commit }) {
    try {
      const { data } = await axios.get("/ddd");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_DDD", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async searchAccount({ commit }) {
    try {
      const { data } = await axios.post("/fields/account_line", {});
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_SEARCH_ACCOUNT", data.data);
    } catch (error) {
      throw new Error(error);
    }
  }
};

const mutations = {
  SET_PLANS: (state, plans) => {
    state.plans = plans;
  },

  SET_DDD: (state, ddd) => {
    state.ddd = ddd;
  },

  SET_SEARCH_ACCOUNT: (state, account) => {
    state.account_line = account;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
