import axios from "@/axios.config";

const state = {
  tagList: [],
  bodyTags: []
};

const actions = {
  async loadTags({ commit }) {
    try {
      const { data } = await axios.get("/solicitation_type/tag");
      if (!data.success) {
        throw new Error(data.message);
      }
      commit("SET_TAG_LIST", data.data);
      commit("SET_BODY_TAGS", data.data);
    } catch (error) {
      throw new Error(error);
    }
  }
};

const mutations = {
  SET_TAG_LIST: (state, tags) => {
    state.tagList = tags;
  },
  SET_BODY_TAGS: (state, tags) => {
    state.bodyTags = tags;
  }
};

export default {
  namespaced: true,
  actions,
  state,
  mutations
};
