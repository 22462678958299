import axios from "../axios.config";
const state = {
  tag_save: [],
  tag_update: [],
  tag_list: [],
  tag_delete: [],
  selected_tag: {}
};

const actions = {
  async loadTag({ commit }) {
    try {
      const { data } = await axios.get("/tag");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_TAG_LIST", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadSelectedTag({ commit }, group) {
    try {
      const { data } = await axios.get(`/tag/${group.id}`);
      commit("SET_SELECTED_TAG", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async changeTagPosition({ dispatch }, params) {
    try {
      const { data } = await axios.post("/tag/change-tag-position", params);
    } catch (error) {
      throw new Error(error);
    }
  },

  async saveTag({ commit, dispatch }, params) {
    try {
      const { data } = await axios.post("/tag/", params);
      if (data.success) {
        dispatch("loadTag", { group: params });
      }
      commit("SET_SAVE", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async updateTag({ commit, dispatch }, group) {
    try {
      const { data } = await axios.patch(`/tag/${group.id}`, group);
      if (data.success) {
        dispatch("loadTag", { group: group });
      }
      commit("SET_UPDATE", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async deleteTag({ commit, dispatch }, group) {
    try {
      const { data } = await axios.delete(`/tag/${group.id}`);
      if (data.success) {
        dispatch("loadTag", { group: group });
      }
      commit("SET_DELETE", data);
    } catch (error) {
      throw new Error(error);
    }
  }
};

const mutations = {
  SET_TAG_LIST: (state, tag_list) => {
    state.tag_list = tag_list;
  },

  SET_SAVE: (state, obj) => {
    state.tag_save = obj;
  },

  SET_UPDATE: (state, obj) => {
    state.tag_update = obj;
  },

  SET_DELETE: (state, obj) => {
    state.tag_delete = obj;
  },

  SET_SELECTED_TAG: (state, selectedGroup) => {
    state.selected_tag = selectedGroup;
  }
};

const getters = {
  getSelectedTag: state => {
    if (state.selected_tag.data) {
      return { ...state.selected_tag.data };
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
