import axios from "@/axios.config";

const state = {
  companies: []
};

const actions = {
  async loadCompanies({ commit }) {
    try {
      const { data } = await axios.get("/company/");
      if (!data.success) {
        throw new Error(data.message);
      }
      commit("SET_COMPANY", data);
    } catch (error) {
      throw new Error(error);
    }
  }
};

const mutations = {
  SET_COMPANY: (state, companies) => {
    state.companies = companies.data;
  }
};

export default {
  namespaced: true,
  actions,
  state,
  mutations
};
