import axios from "../axios.config";
import Swal from "sweetalert2";

const state = {
  provider_save: [],
  provider_update: [],
  provider_list: [],
  selected_provider: {},
  administration_save: {},
  administration_list: [],
  administration_update: [],
  selected_Administration: {},
  operadoras_join: []
};

const actions = {
  async loadProvider({ commit }) {
    try {
      const { data } = await axios.get("/provider/");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_PROVIDER_LIST", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadSelectedProvider({ commit }, provider) {
    try {
      const { data } = await axios.get(`/provider/${provider.id}`);
      commit("SET_SELECTED_PROVIDER", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async saveSolicitationTypeProvider({ commit, dispatch }, params) {
    try {
      const { data } = await axios.post("/provider/save-solicitation", params);
      if (data.success === false) {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  async saveProvider({ commit, dispatch }, params) {
    try {
      const { data } = await axios.post("/provider/", params);
      if (data.success) {
        dispatch("loadProvider", { provider: params });
      }
      commit("SET_SAVE", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async updateProvider({ commit, dispatch }, provider) {
    try {
      const { data } = await axios.patch(`/provider/${provider.id}`, provider);

      commit("SET_UPDATE", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async updateTagsSolicitationType({ commit }, provider_id) {
    try {
      const { data } = await axios.post(`/provider/update-tags-solicitation-type`, provider_id);
      commit("SET_UPDATE", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async deleteProvider({ dispatch }, provider) {
    try {
      const { data } = await axios.delete(`/provider/${provider.id}`);
      if (data.success) {
        await dispatch("loadProvider", { provider: provider });
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  // async loadManager({ commit }, providerId)  {
  async loadManager({ commit }, provider_id) {
    try {
      commit("SET_ADMINISTRATION_LIST", []);
      const { data } = await axios.get(`/provider_administrator/` + provider_id);
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_ADMINISTRATION_LIST", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async saveAdm({ commit, dispatch }, params) {
    try {
      const { data } = await axios.post("/provider_administrator/", params);
      commit("SET_SAVE_ADMINISTRATION", data);
      if (data.success) {
        await dispatch("loadManager");
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  async deleteManager({ dispatch }, administrator) {
    try {
      const { data } = await axios.delete(
        `/provider_administrator/${administrator.id}`
      );
      if (data.success) {
        await dispatch("loadManager", { administrator: administrator });
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadSelectedManager({ commit }, id) {
    try {
      const { data } = await axios.get(`/provider_administrator/${id}`);
      commit("SET_SELECTED_ADMINISTRATION", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadOperadorasJoin({ commit }) {
    try {
      const { data } = await axios.get(`/provider/operadoras-join`);
      commit("SET_OPERADORAS_JOIN", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async updateManager({ commit, dispatch }, administrator) {
    try {
      const { data } = await axios.patch(
        `/provider_administrator/${administrator.provider_id}`,
        administrator
      );
      commit("SET_UPDATE_MANAGER", data);
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
};

const mutations = {
  SET_PROVIDER_LIST: (state, provider_list) => {
    state.provider_list = provider_list;
  },

  SET_SAVE: (state, obj) => {
    state.provider_save = obj;
  },

  SET_UPDATE: (state, obj) => {
    state.provider_update = obj;
  },

  SET_SELECTED_PROVIDER: (state, selectedProvider) => {
    state.selected_provider = selectedProvider;
  },

  SET_SAVE_ADMINISTRATION: (state, obj) => {
    state.administration_save = obj;
  },

  SET_ADMINISTRATION_LIST: (state, administration_list) => {
    state.administration_list = administration_list.data;
  },

  SET_SELECTED_ADMINISTRATION: (state, selectedAdministrator) => {
    state.selected_Administration = selectedAdministrator;
  },

  SET_OPERADORAS_JOIN: (state, data) => {
    state.operadoras_join = data.data;
  },

  SET_UPDATE_MANAGER: (state, obj) => {
    state.administration_update = obj;
  }
};

const getters = {
  getSelectedProvider: state => {
    if (state.selected_provider.data) {
      return { ...state.selected_provider.data };
    }
  },

  getProvider: state => {
    return [...state.provider_list];
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
