import axios from "../axios.config";

const state = {
  rules: [],
  list_rules: [],
  approval_rules: [],
  validators_list: [],
  requesters_list: [],
  users_list: [],
  fields: [],
  approvers: [],
  EmailContent: [],
  messageRules: "",
  action_type_list: [],
  tag_list: []
};

const actions = {
  async loadSolicitationTypeStatus({ commit }) {
    try {
      const { data } = await axios.get("/solicitation_status/");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_SOLICITATION_STATUS", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadRules({ commit }, params) {
    try {
      const { data } = await axios.get("rule?solicitation_type=" + params.id);
      if (data.success === false) {
        commit("setMessageRules", data.message);
      }
      commit("setRules", data.data);
      commit("setMessageRules", data.message);
    } catch (error) {
      throw new Error(error);
    }
  },

  async loadTagList({ commit }, params) {
    try {
      const { data } = await axios.get("/solicitation_type/tag?solicitation_type=" + params.id);
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_TAG_LIST", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadValidators({ commit }) {
    try {
      const { data } = await axios.get("/user?type=validator_show");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("setValidators", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadRequesters({ commit }) {
    try {
      const { data } = await axios.get("/user?type=requester");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("setRequesters", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadUsers({ commit }) {
    try {
      const { data } = await axios.get("/user");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("setUsers", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async loadActionTypes({ commit }) {
    try {
      const { data } = await axios.get("/action_type/");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_ACTION_TYPE", data);
    } catch (error) {
      throw new Error(error);
    }
  }
};
const mutations = {
  setRules: (state, rules) => {
    state.rules = rules;
  },
  SET_SOLICITATION_STATUS: (state, status) => {
    state.status = status;
  },
  SET_TAG_LIST: (state, tagList) => {
    state.tag_list = tagList;
  },
  SET_ACTION_TYPE: (state, actions) => {
    state.action_type_list = actions;
  },
  setValidators: (state, validatorsList) => {
    state.validators_list = validatorsList.data;
  },
  setRequesters: (state, requestersList) => {
    state.requesters_list = requestersList.data;
  },
  setUsers: (state, usersList) => {
    state.users_list = usersList.data;
  },
  setApprovers: (state, rules) => {
    state.approvers = rules;
  },
  setFields: (state, fields) => {
    state.fields = fields;
  },
  setMessageRules: (state, rules) => {
    state.messageRules = rules;
  }
};
const getters = {
  getTags: state => {
    return state.tag_list.data[0].tags;
  },
  getTagsFormated: state => {
    return state.tag_list.data[0].tags_at_format;
  },
  getApprovalRules: state => {
    return state.approval_rules;
  },
  getRules: state => {
    return state.rules;
  },
  getStatus: state => (index) => {
    return state.status.data[state.rules[index].conditions[0].type];
  },
  getSelectedStatus: state => (index) => {
    return state.rules[index].conditions[0].type_value;
  }
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
