const Token = {};
const localStorage = window.localStorage;

Token.hasToken = function() {
  return localStorage.getItem("token") ? true : false;
};
Token.getToken = function() {
  return localStorage.getItem("token");
};
Token.setToken = function(token) {
  localStorage.setItem("token", token);
};

Token.clearToken = function() {
  return localStorage.removeItem("token");
};

module.exports = Token;
