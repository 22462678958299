import axios from "../axios.config";

const state = {
  persons: []
};

const actions = {
  async loadPersons({ commit }) {
    try {
      const { data } = await axios.get("/persons");
      if (data.success === false) {
        throw new Error(data.message);
      }
      commit("SET_PERSONS_LIST", data);
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
};

const mutations = {
  SET_PERSONS_LIST: (state, persons_list) => {
    state.persons = persons_list;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
