<template>
    <v-row justify="center">
      <v-dialog v-model="modalStatus" class="mx-auto" persistent max-width="70%">
        <v-card outlined>
          <v-card-title>
            <v-icon left color="primary" large> mdi-store-cog-outline </v-icon>
            <span class="text-h6">Pendências Configuração</span>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <v-btn color="blue-grey darken-2" text @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-container>
            <v-data-table
              :headers="headers"
              :items="listItens"
              :items-per-page="10"
              :search="search"
              :loading="loading"
              class="elevation-5"
            ></v-data-table>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      search: '',
      loading: true,
      modalStatus: true,
      listItens: [],
      headers: [
        { text: 'Menu', value: 'category' },
        { text: 'Item', value: 'name' },
        { text: 'Pendência', value: 'error' },
      ],
    };
  },

  async mounted() {
    await this.loadPendencyConfiguration().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.listItens = this.pendency_configuration_list;
    this.loading = false;
  },

  computed: {
    ...mapState('pendencyConfiguration', ['pendency_configuration_list']),
  },

  methods: {
    ...mapActions('pendencyConfiguration', ['loadPendencyConfiguration']),
    closeDialog() {
      this.$emit("closeDialog", false);
    },
  }
};
</script>
